import pluralize from "pluralize";

const STOP_WORDS = new Set([
  " ",
  "a",
  "an",
  "and",
  "by",
  "for",
  "of",
  "the",
  "with",
]);
const REGEX_PUNCTUATION = /[,.:;&/-]/g;

/****************************************************************
  Rank Score
****************************************************************/
export const MATCH_TYPES = {
  EXACT: 5,
  "EXACT PLR": 4,
  BROAD: 3,
  "BROAD PLR": 2,
  "--": 1,
};

class RankScoreCalculator {
  normalize(input) {
    return input
      .toLowerCase()
      .replaceAll(REGEX_PUNCTUATION, " ")
      .replaceAll("\n", " ")
      .replaceAll("\r", " ")
      .split(" ")
      .filter((x) => !STOP_WORDS.has(x));
  }

  plr(arr) {
    return arr.map((elm) => pluralize.singular(elm));
  }

  exact(title, keyword) {
    var r = new RegExp(`( |^)${keyword.join(" ")}( |$)`);
    return title.join(" ").match(r);
  }

  exactPLR(title, keyword) {
    var r = new RegExp(`( |^)${keyword.join(" ")}( |$)`);
    return title.join(" ").match(r);
  }

  broad(titleSet, keyword) {
    var result = keyword.filter((x) => !titleSet.has(x));

    return result.length == 0;
  }

  broadPLR(titleSet, keyword) {
    var result = keyword.filter((x) => !titleSet.has(x));

    return result.length == 0;
  }
}

export const makeRankInput = (text) => {
  let rankScoreCalculator = new RankScoreCalculator();
  let textExcludeNull = text || "";
  let input = rankScoreCalculator.normalize(textExcludeNull);
  let inputPLR = rankScoreCalculator.plr(input);
  let inputSet = new Set(input);
  let inputPLRSet = new Set(inputPLR);

  return {
    input: input,
    inputPLR: inputPLR,
    inputSet: inputSet,
    inputPLRSet: inputPLRSet,
  };
};

export const detect = (keywordObj, rankInput) => {
  let rankScoreCalculator = new RankScoreCalculator();
  let rankKeywords = rankScoreCalculator.normalize(keywordObj.phrase);
  let rankKeywordsPLR = rankScoreCalculator.plr(rankKeywords);

  if (rankScoreCalculator.exact(rankInput.input, rankKeywords)) {
    return { matchType: "EXACT", rankScore: keywordObj.juice };
  } else if (
    rankScoreCalculator.exactPLR(rankInput.inputPLR, rankKeywordsPLR)
  ) {
    return {
      matchType: "EXACT PLR",
      rankScore: keywordObj.juice * 0.7,
    };
  } else if (rankScoreCalculator.broad(rankInput.inputSet, rankKeywords)) {
    return {
      matchType: "BROAD",
      rankScore: keywordObj.juice * 0.3,
    };
  } else if (
    rankScoreCalculator.broadPLR(rankInput.inputPLRSet, rankKeywordsPLR)
  ) {
    return {
      matchType: "BROAD PLR",
      rankScore: keywordObj.juice * 0.21,
    };
  } else {
    return { matchType: "--", rankScore: 0 };
  }
};

export default new RankScoreCalculator();
