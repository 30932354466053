<template>
  <div>
    <div class="text-xl font-bold mb-2">Niche Analysis</div>
    <div class="flex flex-row mb-2">
      <div class="flex-1 mr-2">
        <Dropdown
          v-model="selectedNicheId"
          :options="niches"
          :filter="true"
          :loading="loadingNiches"
          optionLabel="sheet_name"
          optionValue="id"
          placeholder="Select a Niche Analysis"
          style="width: 100%"
        />
      </div>
      <Button @click="reloadDataSource()">Import</Button>
    </div>

    <TabView>
      <TabPanel header="Keyword Trends">
        <div>
          <div class="flex flex-wrap mb-2">
            <div class="flex flex-column mr-2">
              <div class="text-sm font-bold mb-2">Title</div>
              <div>
                <Dropdown
                  v-model="filterState.title"
                  :options="matchTypeOptions"
                  placeholder="All"
                  showClear
                  style="min-width: 150px"
                />
              </div>
            </div>

            <div class="flex flex-column">
              <div class="text-sm font-bold mb-2">Bullet</div>
              <div>
                <Dropdown
                  v-model="filterState.bullet"
                  :options="matchTypeOptions"
                  placeholder="All"
                  showClear
                  style="min-width: 150px"
                />
              </div>
            </div>

            <div class="flex flex-column">
              <div class="text-sm font-bold mb-2">Search Term</div>
              <div>
                <Dropdown
                  v-model="filterState.search_term"
                  :options="matchTypeOptions"
                  placeholder="All"
                  showClear
                  style="min-width: 150px"
                />
              </div>
            </div>

            <div class="flex flex-column">
              <div class="text-sm font-bold mb-2">Description</div>
              <div>
                <Dropdown
                  v-model="filterState.description"
                  :options="matchTypeOptions"
                  placeholder="All"
                  showClear
                  style="min-width: 150px"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-wrap mb-2">
            <div class="flex flex-column">
              <div class="text-sm font-bold mb-2">Ranking Score</div>
              <div class="flex flex-row">
                <InputNumber
                  v-model="filterState.ranked_score.gte"
                  mode="decimal"
                  :step="1"
                  placeholder="Min"
                  class="condition-inputnumber"
                />
                <InputNumber
                  v-model="filterState.ranked_score.lte"
                  mode="decimal"
                  :step="1"
                  placeholder="Max"
                  class="condition-inputnumber"
                />
              </div>
            </div>

            <div class="flex flex-column">
              <div class="text-sm font-bold mb-2">SV</div>
              <div class="flex flex-row mb-2">
                <InputNumber
                  v-model="filterState.sv.gte"
                  mode="decimal"
                  :step="1"
                  placeholder="Min"
                  class="condition-inputnumber"
                />
                <InputNumber
                  v-model="filterState.sv.lte"
                  mode="decimal"
                  :step="1"
                  placeholder="Max"
                  class="condition-inputnumber"
                />
              </div>
            </div>

            <div class="flex flex-column mb-2">
              <div class="text-sm font-bold mb-2">Ranked Products</div>
              <div class="flex flex-row">
                <InputNumber
                  v-model="filterState.ranked_products.gte"
                  mode="decimal"
                  :step="1"
                  placeholder="Min"
                  class="condition-inputnumber"
                />
                <InputNumber
                  v-model="filterState.ranked_products.lte"
                  mode="decimal"
                  :step="1"
                  placeholder="Max"
                  class="condition-inputnumber"
                />
              </div>
            </div>
          </div>
          <div
            class="flex justify-content-end align-content-center mb-2"
            style="min-height: 40px"
          >
            <div class="mr-2">
              <Button class="p-button-link" @click="clearFilters">
                Clear Filters
              </Button>
            </div>
            <div>
              <Button class="handleSearch" @click="handleFilters">Apply</Button>
            </div>
          </div>
        </div>

        <div
          v-if="isEnableTrademarkInspectFeature"
          class="flex justify-content-end align-content-center mb-2"
        >
          <Button
            @click="inspectDangerTrademark()"
            :disabled="loadingInspectDangerTrademark"
          >
            Inspect Danger Trademark
          </Button>
        </div>

        <div class="text-orange-500">Ranking Product > 3 and SV >= 350</div>

        <DataTable
          :value="keywords"
          v-model:filters="filters"
          dataKey="id"
          class="p-datatable-sm"
          showGridlines
          scrollable
          scrollHeight="1300px"
          :virtualScrollerOptions="{ itemSize: 46 }"
          :loading="loadingKeywords"
          sortField="juice"
          :sortOrder="-1"
          :reorderableColumns="true"
        >
          <template #loading>Loading records, please wait...</template>

          <Column
            header=""
            style="min-width: 60px; max-width: 60px"
            columnKey="row_id"
          >
            <template #body="{ index }">
              {{ formatNumberWithoutZero(index + 1) }}
            </template>
          </Column>

          <Column
            field="phrase"
            header="Keyword"
            style="min-width: 130px"
            sortable
          >
          </Column>

          <Column
            header="Danger Trademark Level"
            style="min-width: 135px"
            sortable
            columnKey="danger-trademark-level"
          >
            <template #body="{ data }">
              <div>
                <span
                  v-html="
                    inspectResult(data.phrase, dangerTrademark[data.phrase])
                  "
                />
              </div>
            </template>
          </Column>

          <Column
            field="juice"
            header="Ranking Score"
            style="min-width: 130px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right" style="width: 100%">
                {{ formatNumberWithoutZero(data[field]) }}
              </div>
            </template>
          </Column>

          <Column
            field="search_volume"
            header="SV"
            style="min-width: 130px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right" style="width: 100%">
                {{ formatNumberWithoutZero(data[field]) }}
              </div>
            </template>
          </Column>

          <Column
            field="title"
            header="Title"
            style="min-width: 135px"
            sortable
          >
            <template #body="{ field, data }">
              <div :class="classForMatchType(data[field])">
                {{ data[field] || "--" }}
              </div>
            </template>
          </Column>

          <Column
            field="bullet"
            header="Bullet"
            style="min-width: 135px"
            sortable
          >
            <template #body="{ field, data }">
              <div :class="classForMatchType(data[field])">
                {{ data[field] || "--" }}
              </div>
            </template>
          </Column>

          <Column
            field="search_term"
            header="Search Term"
            style="min-width: 135px"
            sortable
          >
            <template #body="{ field, data }">
              <div :class="classForMatchType(data[field])">
                {{ data[field] || "--" }}
              </div>
            </template>
          </Column>

          <Column
            field="description"
            header="Description"
            style="min-width: 135px"
            sortable
          >
            <template #body="{ field, data }">
              <div :class="classForMatchType(data[field])">
                {{ data[field] || "--" }}
              </div>
            </template>
          </Column>

          <Column
            field="competitors_title_count"
            header="Competitor Title"
            style="min-width: 135px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right" style="width: 100%">
                {{ data[field] || 0 }}
              </div>
            </template>
          </Column>

          <Column
            field="competitors_bullet_count"
            header="Competitor Bullet"
            style="min-width: 135px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right" style="width: 100%">
                {{ data[field] || 0 }}
              </div>
            </template>
          </Column>

          <Column
            field="relevancy"
            header="Ranked Product"
            style="min-width: 130px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right" style="width: 100%">
                {{ formatNumberWithoutZero(data[field]) }}
              </div>
            </template>
          </Column>
        </DataTable>
      </TabPanel>
      <TabPanel header="Competitors">
        <DataTable
          :value="competitors"
          v-model:expandedRows="expandedRows"
          dataKey="id"
          showGridlines
          scrollable
          :loading="loadingCompetitors"
          :rowClass="competitorsRowClass"
        >
          <template #loading>Loading records, please wait...</template>

          <Column
            :expander="true"
            headerStyle="width: 3rem"
            columnKey="expander"
          />

          <Column
            field="asin"
            header="Brand/ASIN"
            style="min-width: 350px"
            sortable
          >
            <template #body="{ data }">
              <div
                class="w-full flex justify-content-between align-items-center"
              >
                <div class="flex align-items-center">
                  <div class="flex flex-column">
                    <div class="font-bold">
                      {{ data.brand }}
                    </div>
                    <div class="text-sm">
                      {{ data.asin }}
                    </div>
                  </div>
                </div>
                <div class="flex align-items-end" v-if="data.id !== 0">
                  <a
                    :href="amazonListingUrl(data.asin)"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <Button
                      icon="pi pi-external-link"
                      class="p-button-rounded p-button-text"
                    />
                  </a>
                </div>
              </div>
            </template>
          </Column>

          <Column
            field="total_score"
            header="Total Score"
            style="min-width: 115px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="flex flex-column w-full">
                <div class="text-right w-full mb-1">
                  {{ formatNumberWithoutZero(data[field]) }}
                </div>
                <div
                  class="flex justify-content-end w-full"
                  style="height: 10px"
                >
                  <div
                    class="h-full bg-blue-500"
                    :style="{
                      width: barWithPercent(
                        data.total_score,
                        competitorMaxTotalScore
                      ),
                    }"
                  />
                </div>
              </div>
            </template>
          </Column>

          <Column
            field="title_score"
            header="Title Score"
            style="min-width: 115px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="flex flex-column w-full">
                <div class="text-right w-full mb-1">
                  {{ formatNumberWithoutZero(data[field]) }}
                </div>
                <div
                  class="flex justify-content-end w-full"
                  style="height: 10px"
                >
                  <div
                    class="h-full bg-teal-400"
                    :style="{
                      width: barWithPercent(
                        data.title_score,
                        competitorMaxTitleScore
                      ),
                    }"
                  />
                </div>
              </div>
            </template>
          </Column>

          <Column
            field="bullet_score"
            header="Bullet Score"
            style="min-width: 115px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="flex flex-column w-full">
                <div class="text-right w-full mb-1">
                  {{ formatNumberWithoutZero(data[field]) }}
                </div>
                <div
                  class="flex justify-content-end w-full"
                  style="height: 10px"
                >
                  <div
                    class="h-full bg-teal-400"
                    :style="{
                      width: barWithPercent(
                        data.bullet_score,
                        competitorMaxBulletScore
                      ),
                    }"
                  />
                </div>
              </div>
            </template>
          </Column>

          <Column
            field="sales"
            header="Sales"
            style="min-width: 115px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right" style="width: 100%">
                {{ formatNumberWithoutZero(data[field]) }}
              </div>
            </template>
          </Column>

          <Column
            field="revenue"
            header="Revenue"
            style="min-width: 115px"
            sortable
          >
            <template #body="{ field, data }">
              <div class="text-right" style="width: 100%">
                {{ formatNumberWithoutZero(data[field]) }}
              </div>
            </template>
          </Column>

          <template #expansion="{ data }">
            <div class="w-full" v-if="data.id !== 0">
              <div class="mb-2">
                <div
                  class="flex justify-content-between align-items-center mb-1"
                >
                  <div class="text-xl font-bold">Title</div>
                  <div>
                    <Button
                      icon="pi pi-copy"
                      class="p-button-rounded p-button-text"
                      @click="copyText(data.title)"
                    />
                  </div>
                </div>
                <div class="w-full p-2 border-solid">
                  {{ data.title }}
                </div>
              </div>

              <div
                v-for="(bullet, i) in data.bullets"
                :key="bullet"
                class="mb-2"
              >
                <div
                  class="flex justify-content-between align-items-center mb-1"
                >
                  <div class="text-xl font-bold">#{{ i + 1 }} Bullet</div>
                  <div>
                    <Button
                      icon="pi pi-copy"
                      class="p-button-rounded p-button-text"
                      @click="copyText(bullet)"
                    />
                  </div>
                </div>
                <div class="w-full p-2 border-solid">
                  {{ bullet }}
                </div>
              </div>
            </div>
          </template>
        </DataTable>
      </TabPanel>
      <TabPanel header="Sensitive Words">
        <div
          class="font-bold text-green-700"
          v-if="detectedResults.length === 0"
        >
          No Sensitive words are detected.
        </div>
        <div class="flex flex-column gap-2 mb-5" v-else>
          <div>
            We detected the potential
            <span class="text-red">sensitive words</span>. Please review your
            content carefully.
          </div>
        </div>
        <div v-for="result in detectedResults" :key="result.title">
          <div class="font-bold text-lg">
            {{ result.title }}
          </div>
          <div>
            <ul>
              <li v-for="word in result.detectedWords" :key="word">
                {{ word }}
              </li>
            </ul>
          </div>
        </div>

        <hr />

        <div class="font-bold text-lg mb-3">Sensitive Database</div>
        <div class="mb-3">
          Reference Sources:
          <ul>
            <li>
              <a href="https://www.wearesellers.com/question/60823">
                亚马逊上传链接立马变狗问题
              </a>
            </li>
            <li>
              <a href="https://zhuanlan.zhihu.com/p/387051579">
                亚马逊敏感词汇大全
              </a>
            </li>
          </ul>
        </div>
        <div>Sensitive word List</div>
        <ul>
          <li v-for="word in sensitiveWords" :key="word">{{ word }}</li>
        </ul>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import {
  defineComponent,
  inject,
  reactive,
  ref,
  watch,
  getCurrentInstance,
  computed,
} from "vue";
import { useVModel } from "@vueuse/core";
import { FilterOperator, FilterMatchMode } from "primevue/api";
import { useToast } from "primevue/usetoast";
import ClipboardJS from "clipboard";

import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

import ListingService from "@/services/listing_service";
import NicheAnalysisService from "@/services/niche_analysis_service";
import UserInfoService from "@/services/user_info_service";

import {
  makeRankInput,
  detect,
  MATCH_TYPES,
} from "@/components/ListingBuilder/RankScoreCalculator";
import { formatNumberWithoutZero } from "@/utils/formatter";
import store from "@/store";
import { sensitiveWords } from "@/utils/sensitive_detector";

export default defineComponent({
  components: {
    Button,
    Column,
    DataTable,
    Dropdown,
    InputNumber,
    TabView,
    TabPanel,
  },

  get channels() {
    return {
      TextsSearchJobChannel: {
        connected() {
          console.debug("connected");
        },
        rejected() {},
        received(data) {
          switch (data.commit) {
            case "SUCCESS":
              this.updateDangerTrademark(data.payload);
              break;
            case "JOB_FINISH":
              this.finishForInspectDangerTrademark();
              break;
          }
        },
        disconnected() {},
      },
    };
  },

  props: ["productId", "nicheId", "myListing"],
  emits: ["update:nicheId", "competitorLoaded"],
  setup(props, { emit }) {
    const listingVersion = inject("listingVersion");
    const instance = getCurrentInstance();
    const cable = instance.proxy.$root.$cable;

    const isEnableTrademarkInspectFeature = computed(() => {
      let featureCheck = store.getters.teamFeatures.find(
        (e) => e === "trademark_inspect_feature"
      );
      return featureCheck;
    });

    watch(
      () => props.productId,
      async () => {
        loadNiches();
      }
    );

    const loadNiches = async () => {
      if (!props.productId) {
        return;
      }

      selectedNicheId.value = null;
      niches.value = [];
      keywords.value = [];

      let params = {
        "q[product_id_eq]": props.productId,
      };

      loadingNiches.value = true;
      NicheAnalysisService.list(params)
        .then(({ data }) => {
          niches.value = data.niches;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          loadingNiches.value = false;
        });
    };

    const reloadDataSource = () => {
      loadKeywords();
      loadCompetitors();
    };

    const loadKeywords = async () => {
      if (!selectedNicheId.value) {
        keywords.value = [];
        return;
      }

      let params = {
        niche_id: selectedNicheId.value,
      };

      loadingKeywords.value = true;
      loadingInspectDangerTrademark.value = true;
      ListingService.listKeywords(params)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Keywords not found!",
              life: 3000,
            });
          } else {
            keywords.value = data.keywords;
            loadingInspectDangerTrademark.value = false;
            calculateRankScores();
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          loadingKeywords.value = false;
        });
    };

    const loadCompetitors = async () => {
      if (!selectedNicheId.value) {
        competitors.value = [];
        return;
      }

      let mylisting;
      if (listingVersion) {
        mylisting = {
          id: 0,
          brand: "My Listing",
          total_score: listingVersion.total_score,
          title_score: listingVersion.title_score,
          bullet_score: listingVersion.bullet_score,
        };
      }

      let params = {
        niche_id: selectedNicheId.value,
      };

      loadingCompetitors.value = true;
      ListingService.listCompetitors(params)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: "Keywords not found!",
              life: 3000,
            });
          } else {
            competitors.value = [mylisting, ...data.competitors];
            emit("competitorLoaded", competitors.value);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          loadingCompetitors.value = false;
        });
    };

    const inspectDangerTrademark = () => {
      loadingInspectDangerTrademark.value = true;
      channelRoomUUID.value = crypto.randomUUID();
      UserInfoService.show().then(() => {
        cable.subscribe({
          channel: "TextsSearchJobChannel",
          room: channelRoomUUID.value,
        });
      });

      let keywordPhrases = keywords.value.map((keyword) => {
        return keyword.phrase;
      });
      let payload = {
        texts: keywordPhrases,
        room: channelRoomUUID.value,
      };

      // reset danger trademark
      dangerTrademark.value = {};
      ListingService.listTrademarkInspects(payload)
        .then(({ data }) => {
          if (data.error) {
            console.error(data.error.message);
            toast.add({
              severity: "error",
              summary: data.error.message,
              life: 3000,
            });
          } else {
            toast.add({
              severity: "info",
              summary: "Trademark inspecting...",
              life: 3000,
            });
          }
        })
        .catch((e) => {
          console.error(e);
        });
    };

    const updateDangerTrademark = (payload) => {
      let respDangerTrademark = JSON.parse(payload);
      dangerTrademark.value = {
        ...dangerTrademark.value,
        ...respDangerTrademark,
      };
    };

    const finishForInspectDangerTrademark = () => {
      cable.unsubscribe("TextsSearchJobChannel");
      loadingInspectDangerTrademark.value = false;
      toast.add({
        severity: "success",
        summary: "All trademarks are inspected",
      });
    };

    const inspectResult = (phrase, trademarkInfo) => {
      if (trademarkInfo?.is_same) {
        return `<span class="text-pink-700 font-semibold">VERY DANGER</span>`;
      }
      if (!trademarkInfo?.trademarks) {
        return "<span>--</span>";
      }

      let phraseIncludesTrademark = "";
      for (let index = 0; index < trademarkInfo.trademarks.length; index++) {
        if (phrase.includes(trademarkInfo.trademarks[index])) {
          phraseIncludesTrademark = trademarkInfo.trademarks[index];
          break;
        }
      }
      if (phraseIncludesTrademark != "") {
        return (
          `<span class="text-pink-700 font-semibold">` +
          phraseIncludesTrademark.toUpperCase() +
          `</span>`
        );
      }

      let partTrademarksSplit = new Set();
      let phraseSplit = phrase.split(" ");
      for (let index = 0; index < trademarkInfo.trademarks.length; index++) {
        let trademarksSplit = trademarkInfo.trademarks[index].split(" ");
        let result = phraseSplit.filter((e) => {
          return trademarksSplit.indexOf(e) > -1;
        });
        if (result.length > 0) {
          partTrademarksSplit.add(result.join(" "));
        }
      }
      if (partTrademarksSplit.size == 0) {
        return "<span>--</span>";
      }

      return (
        `<span class="text-orange-500 font-semibold">` +
        [...partTrademarksSplit].join(" | ") +
        "</span>"
      );
    };

    const toast = useToast();
    const loadingNiches = ref(false);
    const loadingKeywords = ref(false);
    const loadingCompetitors = ref(false);
    const loadingInspectDangerTrademark = ref(true);

    const niches = ref([]);
    const selectedNicheId = useVModel(props, "nicheId", emit);
    const keywords = ref([]);

    const competitors = ref([]);
    const expandedRows = ref([]);
    const competitorMaxTotalScore = ref(0);
    const competitorMaxTitleScore = ref(0);
    const competitorMaxBulletScore = ref(0);

    const dangerTrademark = ref({});
    const channelRoomUUID = ref("");

    /****************************************************************
      Filter
    ****************************************************************/
    const filters = reactive({});
    const filterState = reactive({
      title: null,
      bullet: null,
      search_term: null,
      description: null,
      ranked_score: {
        gte: null,
        lte: null,
      },
      sv: {
        gte: null,
        lte: null,
      },
      ranked_products: {
        gte: null,
        lte: null,
      },
    });
    const matchTypeOptions = ["EXACT", "EXACT PLR", "BROAD", "BROAD PLR", "--"];

    const clearFilters = () => {
      filterState.title = null;
      filterState.bullet = null;
      filterState.search_term = null;
      filterState.description = null;
      filterState.ranked_score.gte = null;
      filterState.ranked_score.lte = null;
      filterState.sv.gte = null;
      filterState.sv.lte = null;
      filterState.ranked_products.gte = null;
      filterState.ranked_products.lte = null;
    };

    const handleFilters = () => {
      textToFilters("title", filterState.title);

      textToFilters("bullet", filterState.bullet);

      textToFilters("search_term", filterState.search_term);

      textToFilters("description", filterState.description);

      conditionToFilters(
        "ranked_score",
        filterState.ranked_score.gte,
        filterState.ranked_score.lte
      );

      conditionToFilters(
        "search_volume",
        filterState.sv.gte,
        filterState.sv.lte
      );

      conditionToFilters(
        "relevancy",
        filterState.ranked_products.gte,
        filterState.ranked_products.lte
      );
    };

    const textToFilters = (filterColumn, value) => {
      if (!value) {
        delete filters[filterColumn];
        return;
      }

      filters[filterColumn] = {
        value: value,
        matchMode: FilterMatchMode.EQUALS,
      };
    };

    const conditionToFilters = (filterColumn, gte, lte) => {
      if (gte && lte) {
        filters[filterColumn] = {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: gte,
              matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
            },
            {
              value: lte,
              matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
            },
          ],
        };
      } else if (gte) {
        filters[filterColumn] = {
          value: gte,
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        };
      } else if (lte) {
        filters[filterColumn] = {
          value: lte,
          matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
        };
      } else {
        delete filters[filterColumn];
      }
    };

    /****************************************************************
      Rank Score
    ****************************************************************/
    const calculateRankScores = () => {
      let resp = {
        totalScore: 0,
        titleScore: 0,
        bulletScore: 0,
        totalRank: 20,
        titleRank: 20,
        bulletRank: 20,
      };

      if (keywords.value.length === 0) {
        return resp;
      }

      let rankInputTitle = makeRankInput(listingVersion.title);

      let rankInputBps = [
        makeRankInput(listingVersion.bullet_point_1),
        makeRankInput(listingVersion.bullet_point_2),
        makeRankInput(listingVersion.bullet_point_3),
        makeRankInput(listingVersion.bullet_point_4),
        makeRankInput(listingVersion.bullet_point_5),
      ];

      let rankInputSearchTerm = makeRankInput(listingVersion.search_term);
      let rankInputDescription = makeRankInput(listingVersion.description);

      let len = keywords.value.length;
      let result;
      let bulletMatchType;
      let bulletScore;
      for (let i = 0; i < len; i++) {
        result = detect(keywords.value[i], rankInputTitle);

        // Title
        keywords.value[i].title = result.matchType;
        resp.titleScore += result.rankScore;
        if (result.rankScore > 0) {
          console.debug(
            `[${result.matchType}](${Math.round(result.rankScore)}): ${
              keywords.value[i].phrase
            }`
          );
        }

        // Bullet
        bulletMatchType = "--";
        bulletScore = 0;
        for (let j = 0; j < 5; j++) {
          result = detect(keywords.value[i], rankInputBps[j]);

          if (MATCH_TYPES[result.matchType] > MATCH_TYPES[bulletMatchType]) {
            bulletMatchType = result.matchType;
            bulletScore = result.rankScore;
          }
        }
        keywords.value[i].bullet = bulletMatchType;
        resp.bulletScore += bulletScore;

        // Search Term
        result = detect(keywords.value[i], rankInputSearchTerm);
        keywords.value[i].search_term = result.matchType;

        // Search Term
        result = detect(keywords.value[i], rankInputDescription);
        keywords.value[i].description = result.matchType;
      }

      resp.titleScore = Math.round(resp.titleScore);
      resp.bulletScore = Math.round(resp.bulletScore);
      resp.totalScore = resp.titleScore + resp.bulletScore;

      // Update My Listing
      let i = competitors.value.findIndex((c) => c.id === 0);
      if (i >= 0) {
        competitors.value[i].total_score = resp.totalScore;
        competitors.value[i].title_score = resp.titleScore;
        competitors.value[i].bullet_score = resp.bulletScore;
      }

      // Find the max score
      let clen = competitors.value.length;
      competitorMaxTotalScore.value = 0;
      competitorMaxTitleScore.value = 0;
      competitorMaxBulletScore.value = 0;
      for (let i = 0; i < clen; i++) {
        if (competitors.value[i].total_score >= competitorMaxTotalScore.value) {
          competitorMaxTotalScore.value = competitors.value[i].total_score;
        }

        if (competitors.value[i].title_score >= competitorMaxTitleScore.value) {
          competitorMaxTitleScore.value = competitors.value[i].title_score;
        }

        if (
          competitors.value[i].bullet_score >= competitorMaxBulletScore.value
        ) {
          competitorMaxBulletScore.value = competitors.value[i].bullet_score;
        }
      }

      return resp;
    };

    const classForMatchType = (matchType) => {
      if (!matchType || matchType === "--") {
        return "";
      }

      return "match-type-" + matchType.toLocaleLowerCase().replaceAll(" ", "-");
    };

    const copyText = (text) => {
      ClipboardJS.copy(text);
      toast.add({
        severity: "success",
        summary: "Copied",
        life: 3000,
      });
    };

    const amazonImageUrl = (asin) => {
      return `https://ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=${asin}&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=SL300`;
    };

    const amazonListingUrl = (asin) => {
      return `https://www.amazon.com/dp/${asin}`;
    };

    const competitorsRowClass = (data) => {
      if (data.id === 0) {
        return "bg-yellow-300";
      }
    };

    const barWithPercent = (val, maxValue) => {
      if (maxValue === 0) {
        return "0%";
      }

      return Math.round((val / maxValue) * 100) + "%";
    };

    /****************************************************************
      Sensitive Words
    ****************************************************************/

    const detectedResults = ref([]);
    const updateDetectedResults = (results) => {
      detectedResults.value = results;
    };

    return {
      loadingNiches,
      loadingKeywords,
      loadingCompetitors,
      loadingInspectDangerTrademark,

      niches,
      selectedNicheId,

      keywords,
      competitors,
      reloadDataSource,
      expandedRows,
      competitorMaxTotalScore,
      competitorMaxTitleScore,
      competitorMaxBulletScore,

      filters,
      filterState,
      matchTypeOptions,
      clearFilters,
      handleFilters,

      copyText,
      amazonImageUrl,
      amazonListingUrl,
      competitorsRowClass,
      barWithPercent,

      formatNumberWithoutZero,

      // call outside
      calculateRankScores,
      classForMatchType,

      inspectDangerTrademark,
      updateDangerTrademark,
      finishForInspectDangerTrademark,
      dangerTrademark,
      inspectResult,
      isEnableTrademarkInspectFeature,

      detectedResults,
      updateDetectedResults,
      sensitiveWords,
    };
  },
});
</script>

<style lang="scss" scoped>
.match-type-exact {
  color: green;
}

.match-type-exact-plr {
  color: lightgreen;
}

.match-type-broad {
  color: blue;
}

.match-type-broad-plr {
  color: lightblue;
}

.condition-inputnumber {
  ::v-deep .p-inputnumber-input {
    width: 100px;
    margin-right: 0.5rem;
  }
}

.text-red {
  color: red;
}
</style>
