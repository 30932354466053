export const sensitiveWords = [
  "against mildew",
  "all natural",
  "allergens",
  "anti",
  "anti-fouling",
  "anti-bacteria",
  "anti-bacterial vinyl",
  "anti-flu",
  "anti-fouling",
  "anti-mildew",
  "anti-mites",
  "anti-mold",
  "anti-oxidation",
  "anti-toxic",
  "anti-virus",
  "antibacterial",
  "antibiotic",
  "antifouling",
  "antifungal",
  "antimicrobial",
  "bacteria",
  "bacteriostatic",
  "bpa-free",
  "bug",
  "ce certified",
  "chemical",
  "destroy",
  "disinfect",
  "drug",
  "dust",
  "dustproof",
  "eco-friendly",
  "eliminate",
  "environment friendly",
  "environmental protection. quality: non-slip",
  "extended service warranties",
  "fda certified",
  "filter air",
  "filters",
  "fungal",
  "fungus",
  "germs",
  "harmless",
  "healthy",
  "high quality",
  "hygience",
  "hypoallergenic",
  "imported",
  "infection",
  "inhibit-algae",
  "insect prevention",
  "insect repellent",
  "insecticidal",
  "insecticide",
  "insects",
  "light",
  "micro-bio",
  "mildew",
  "mites",
  "mitigating any pest",
  "moisture",
  "mold",
  "money-back guarantees",
  "mosquitos",
  "most beautiful",
  "mouse",
  "new arrival",
  "new original",
  "non-injurious",
  "non-poisonous",
  "non-toxic",
  "nontoxic",
  "odourless",
  "pest",
  "pesticide",
  "pests",
  "preservatives",
  "prevent",
  "prevent or inhibit the growth of bacteria",
  "preventing",
  "protect",
  "rat",
  "remove",
  "remove allergens",
  "repel insects",
  "repel or slow down any pest",
  "repel-pesticides",
  "repelient",
  "repelling",
  "residue",
  "resist ultraviolet rays",
  "resistant",
  "rodent",
  "safety",
  "sanitize-blight",
  "satisfaction guarantee",
  "sterilization",
  "sterilize",
  "stop-microbe",
  "toxic",
  "ultraviolet",
  "used to prevent",
  "uv",
  "virus",
  "water filtration",
  "waterproof",
  "with nicotine",
];

export const detectSensitiveWords = (text) => {
  if (text === null) {
    return [];
  }

  const lowerText = text
    .replaceAll("\n", " ")
    .replaceAll(/\s+/g, " ")
    .toLowerCase();

  const alertWords = [];
  // search sensitive words
  sensitiveWords.forEach((s) => {
    const detected = new RegExp(s).test(lowerText);
    if (detected) {
      alertWords.push(s);
    }
  });

  return alertWords;
};
