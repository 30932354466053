<template>
  <div>
    <Card class="mb-3">
      <template #content>
        <div class="font-bold mb-2">
          <span class="text-xl vertical-align-baseline">Listing Version</span>
        </div>
        <div class="flex justify-content-between align-items-end">
          <div class="flex flex-1 flex-row justify-content-between">
            <div class="flex flex-1 flex-column mr-1">
              <div class="condition-title">Product</div>
              <div>
                <Dropdown
                  v-model="listingVersionState.product_id"
                  :options="products"
                  optionLabel="name"
                  optionValue="id"
                  :filter="true"
                  :loading="loadingProducts"
                  placeholder="Select a product"
                  style="width: 100%"
                  @change="handleChangeSelectedProductId"
                />
              </div>
            </div>
            <div class="flex flex-1 flex-column ml-1">
              <div class="condition-title">Version</div>
              <div>
                <Dropdown
                  v-model="listingVersionState.id"
                  :options="listingVersions"
                  optionLabel="name"
                  optionValue="id"
                  :filter="true"
                  :loading="loadingListingVersions"
                  placeholder="Select a version"
                  style="width: 100%"
                />
              </div>
            </div>
          </div>
          <div class="ml-2">
            <Button @click="loadListingVersion">Import</Button>
          </div>
        </div>
      </template>
    </Card>
    <div class="flex flex-row justify-content-between">
      <Card class="flex-none mr-1" style="width: calc(70%)">
        <template #content>
          <KeywordTrends
            :productId="listingVersionState.product_id"
            v-model:nicheId="listingVersionState.niche_id"
            @competitorLoaded="competitorLoaded"
            ref="keywordTrendsComponent"
          />
        </template>
      </Card>
      <Card class="flex-none ml-1" style="width: calc(30%)">
        <template #content>
          <div class="text-xl font-bold mr-2 mb-2">Listing Content</div>

          <div class="flex flex-1 flex-row justify-content-between mb-5">
            <div class="flex flex-1 flex-column mr-1">
              <div class="condition-title">Version Note</div>
              <div>
                <InputText
                  v-model="listingVersionState.note"
                  style="width: 100%"
                />
              </div>
              <div class="flex flex-row justify-content-end gap-1 mt-2">
                <Button
                  @click="confirmDeleteListingVersion"
                  icon="pi pi-trash"
                  v-tooltip.top="'Delete current version'"
                />
                <Button
                  @click="saveListingVersion"
                  icon="pi pi-save"
                  v-tooltip.top="'Save current version'"
                />
                <Button
                  @click="createListingVersion"
                  icon="pi pi-plus"
                  v-tooltip.top="'Save as new version'"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap align-items-center mb-5 gap-1">
            <Card class="card-score">
              <template #content>
                <div class="flex flex-1 flex-column">
                  <div class="flex flex-row justify-content-between mb-3">
                    <div class="font-bold">Total Score</div>
                    <div class="text-pink-300">
                      # {{ listingVersionState.total_rank }}/{{
                        competitorsCount
                      }}
                    </div>
                  </div>
                  <div class="text-2xl font-bold text-right text-blue-700">
                    {{ formatNumber(listingVersionState.total_score) }}
                  </div>
                </div>
              </template>
            </Card>
            <Card class="card-score">
              <template #content>
                <div class="flex flex-1 flex-column">
                  <div class="flex flex-row justify-content-between mb-3">
                    <div class="font-bold">Title Score</div>
                    <div class="text-pink-300">
                      # {{ listingVersionState.title_rank }}/{{
                        competitorsCount
                      }}
                    </div>
                  </div>
                  <div class="text-2xl font-bold text-right text-teal-400">
                    {{ formatNumber(listingVersionState.title_score) }}
                  </div>
                </div>
              </template>
            </Card>
            <Card class="card-score">
              <template #content>
                <div class="flex flex-1 flex-column">
                  <div class="flex flex-row justify-content-between mb-3">
                    <div class="font-bold">Bullet Score</div>
                    <div class="text-pink-300">
                      # {{ listingVersionState.bullet_rank }}/{{
                        competitorsCount
                      }}
                    </div>
                  </div>
                  <div class="text-2xl font-bold text-right text-teal-400">
                    {{ formatNumber(listingVersionState.bullet_score) }}
                  </div>
                </div>
              </template>
            </Card>
            <div class="ml-5">
              <Button
                icon="pi pi-refresh"
                :disabled="blocked"
                @click="calculateRankScores"
              />
            </div>
          </div>

          <ProgressBar
            mode="indeterminate"
            style="height: 0.5em"
            v-show="blocked"
          />

          <div class="mb-2">
            <ByteCountTextArea
              label="Title"
              v-model="listingVersionState.title"
              :maxBytes="200"
            />
          </div>

          <div class="mb-2">
            <ByteCountTextArea
              label="#1 Bullet Point"
              v-model="listingVersionState.bullet_point_1"
              :maxBytes="250"
            />
          </div>

          <div class="mb-2">
            <ByteCountTextArea
              label="#2 Bullet Point"
              v-model="listingVersionState.bullet_point_2"
              :maxBytes="250"
            />
          </div>

          <div class="mb-2">
            <ByteCountTextArea
              label="#3 Bullet Point"
              v-model="listingVersionState.bullet_point_3"
              :maxBytes="250"
            />
          </div>

          <div class="mb-2">
            <ByteCountTextArea
              label="#4 Bullet Point"
              v-model="listingVersionState.bullet_point_4"
              :maxBytes="250"
            />
          </div>

          <div class="mb-2">
            <ByteCountTextArea
              label="#5 Bullet Point"
              v-model="listingVersionState.bullet_point_5"
              :maxBytes="250"
            />
          </div>

          <div class="mb-2">
            <ByteCountTextArea
              label="Search Term"
              v-model="listingVersionState.search_term"
              :maxBytes="250"
            />
          </div>

          <div class="mb-2">
            <ByteCountTextArea
              label="Description"
              v-model="listingVersionState.description"
              :maxBytes="2000"
            />
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  provide,
  readonly,
  reactive,
  ref,
  nextTick,
} from "vue";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import Button from "primevue/button";
import Card from "primevue/card";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import ProgressBar from "primevue/progressbar";

import ByteCountTextArea from "@/components/ListingBuilder/ByteCountTextArea";
import KeywordTrends from "@/components/ListingBuilder/KeywordTrends";

import store from "@/store";
import ProductService from "@/services/product_service";

import { formatYYYYmmddDate, formatNumber } from "@/utils/formatter";
import { detectSensitiveWords } from "@/utils/sensitive_detector";

export default defineComponent({
  components: {
    Button,
    Card,
    Dropdown,
    InputText,
    ProgressBar,

    ByteCountTextArea,
    KeywordTrends,
  },

  setup() {
    onMounted(() => {
      loadProducts();
    });

    const loadProducts = async () => {
      loadingProducts.value = true;

      let params = {};
      ProductService.listProducts(params)
        .then(({ data }) => {
          products.value = data.products;
        })
        .finally(() => {
          loadingProducts.value = false;
        });
    };

    const loadListingVersions = async (productId) => {
      loadingListingVersions.value = true;

      ProductService.listListingVersions(productId)
        .then(({ data }) => {
          listingVersions.value = data.listing_versions;
        })
        .finally(() => {
          loadingListingVersions.value = false;
        });
    };

    const handleChangeSelectedProductId = (event) => {
      loadListingVersions(event.value);
    };

    const confirmDeleteListingVersion = () => {
      // check required fields
      if (!listingVersionState.product_id) {
        toast.add({
          severity: "warn",
          summary: "You don't have a product selected!",
          life: 3000,
        });
        return;
      }

      if (!listingVersionState.id) {
        toast.add({
          severity: "warn",
          summary: "You don't have a version selected!",
          life: 3000,
        });
        return;
      }

      const listingVersion = listingVersions.value.find((record) => {
        return record.id === listingVersionState.id;
      });

      confirm.require({
        message: `Are you sure you want to delete ${listingVersion.name} ?`,
        header: "Deelte Listing Version",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Confirm",
        rejectLabel: "Cancel",
        accept: () => {
          ProductService.deleteListingVersion(
            listingVersionState.product_id,
            listingVersionState.id
          )
            .then(() => {
              toast.add({
                severity: "success",
                summary: "Listing version deleted",
                life: 3000,
              });

              listingVersionState.id = null;
              loadListingVersions(listingVersionState.product_id);
            })
            .catch((e) => {
              console.error(e);

              let message = "Delete listing version fail";
              if (e.response.status === 400 || e.response.status === 401) {
                message = e.response.data?.error;
              }

              toast.add({
                severity: "error",
                summary: "Error",
                detail: `Error: ${message}`,
                life: 3000,
              });
            });
        },
      });
    };

    const saveListingVersion = () => {
      if (!listingVersionState.id) {
        createListingVersion();
        return;
      }

      // check required fields
      if (!listingVersionState.product_id) {
        toast.add({
          severity: "error",
          summary: "Product required!",
          life: 3000,
        });
        return;
      }

      if (!listingVersionState.niche_id) {
        toast.add({
          severity: "error",
          summary: "Niche required!",
          life: 3000,
        });
        return;
      }

      if (listingVersionState.note.trim() === "") {
        toast.add({
          severity: "error",
          summary: "Version Note required!",
          life: 3000,
        });
        return;
      }

      let payload = {
        listing_version: {
          product_id: listingVersionState.product_id,
          niche_analysis_niche_id: listingVersionState.niche_id,
          name: listingVersionName(),
          note: listingVersionState.note,
          title: listingVersionState.title,
          bullet_point_1: listingVersionState.bullet_point_1,
          bullet_point_2: listingVersionState.bullet_point_2,
          bullet_point_3: listingVersionState.bullet_point_3,
          bullet_point_4: listingVersionState.bullet_point_4,
          bullet_point_5: listingVersionState.bullet_point_5,
          total_score: listingVersionState.total_score,
          total_rank: listingVersionState.total_rank,
          title_score: listingVersionState.title_score,
          title_rank: listingVersionState.title_rank,
          bullet_score: listingVersionState.bullet_score,
          bullet_rank: listingVersionState.bullet_rank,
          search_term: listingVersionState.search_term,
          description: listingVersionState.description,
        },
      };

      ProductService.updateListingVersion(
        listingVersionState.product_id,
        listingVersionState.id,
        payload
      )
        .then(() => {
          toast.add({
            severity: "success",
            summary: "Listing Version saved!",
            life: 3000,
          });

          // Reload Listing Versions
          loadListingVersions(listingVersionState.product_id);
        })
        .catch((error) => {
          console.error(error);
          toast.add({
            severity: "error",
            summary: "Save Listing Version Fail!",
            life: 3000,
          });
        });
    };

    const createListingVersion = () => {
      // check required fields
      if (!listingVersionState.product_id) {
        toast.add({
          severity: "error",
          summary: "Product required!",
          life: 3000,
        });
        return;
      }

      if (!listingVersionState.niche_id) {
        toast.add({
          severity: "error",
          summary: "Niche required!",
          life: 3000,
        });
        return;
      }

      if (listingVersionState.note.trim() === "") {
        toast.add({
          severity: "error",
          summary: "Version Note required!",
          life: 3000,
        });
        return;
      }

      let payload = {
        listing_version: {
          product_id: listingVersionState.product_id,
          niche_analysis_niche_id: listingVersionState.niche_id,
          name: listingVersionName(),
          note: listingVersionState.note,
          title: listingVersionState.title,
          bullet_point_1: listingVersionState.bullet_point_1,
          bullet_point_2: listingVersionState.bullet_point_2,
          bullet_point_3: listingVersionState.bullet_point_3,
          bullet_point_4: listingVersionState.bullet_point_4,
          bullet_point_5: listingVersionState.bullet_point_5,
          total_score: listingVersionState.total_score,
          total_rank: listingVersionState.total_rank,
          title_score: listingVersionState.title_score,
          title_rank: listingVersionState.title_rank,
          bullet_score: listingVersionState.bullet_score,
          bullet_rank: listingVersionState.bullet_rank,
          search_term: listingVersionState.search_term,
          description: listingVersionState.description,
        },
      };

      ProductService.createListingVersion(
        listingVersionState.product_id,
        payload
      )
        .then((resp) => {
          toast.add({
            severity: "success",
            summary: "Listing Version created!",
            life: 3000,
          });

          // Reload Listing Versions
          loadListingVersions(listingVersionState.product_id).then(() => {
            listingVersionState.id = resp.data.id;
          });
        })
        .catch((error) => {
          console.error(error);
          toast.add({
            severity: "error",
            summary: "Create Listing Version Fail!",
            life: 3000,
          });
        });
    };

    const listingVersionName = () => {
      // Total Score-Version Note-使用者名稱-日期

      let total_score = listingVersionState.total_score;
      let note = listingVersionState.note;
      let username = store.getters.userName;
      let createDate = formatYYYYmmddDate(new Date());

      return `${total_score}-${note}-${username}-${createDate}`;
    };

    const loadListingVersion = async () => {
      let listingVersion = listingVersions.value.find((record) => {
        return record.id === listingVersionState.id;
      });
      listingVersionState.niche_id = listingVersion.niche_analysis_niche_id;
      listingVersionState.name = listingVersion.name;
      listingVersionState.note = listingVersion.note;
      listingVersionState.title = listingVersion.title;
      listingVersionState.bullet_point_1 = listingVersion.bullet_point_1;
      listingVersionState.bullet_point_2 = listingVersion.bullet_point_2;
      listingVersionState.bullet_point_3 = listingVersion.bullet_point_3;
      listingVersionState.bullet_point_4 = listingVersion.bullet_point_4;
      listingVersionState.bullet_point_5 = listingVersion.bullet_point_5;
      listingVersionState.total_score = listingVersion.total_score;
      listingVersionState.total_rank = listingVersion.total_rank;
      listingVersionState.title_score = listingVersion.title_score;
      listingVersionState.title_rank = listingVersion.title_rank;
      listingVersionState.bullet_score = listingVersion.bullet_score;
      listingVersionState.bullet_rank = listingVersion.bullet_rank;
      listingVersionState.search_term = listingVersion.search_term;
      listingVersionState.description = listingVersion.description;

      await nextTick(); // wait the props set to component
      keywordTrendsComponent.value.reloadDataSource();

      // Update sensitive words
      detectSensitiveWordsFromInputs();
    };

    const confirm = useConfirm();
    const toast = useToast();
    const blocked = ref(false);
    const loadingProducts = ref(false);
    const loadingListingVersions = ref(false);

    const products = ref([]);

    const listingVersions = ref([]);

    const listingVersionState = reactive({
      id: null,
      product_id: null,
      niche_id: null,
      name: "test",
      note: "",
      title: "",
      bullet_point_1: "",
      bullet_point_2: "",
      bullet_point_3: "",
      bullet_point_4: "",
      bullet_point_5: "",
      search_term: "",
      description: "",
      total_score: 0,
      total_rank: 0,
      title_score: 0,
      title_rank: 0,
      bullet_score: 0,
      bullet_rank: 0,
    });
    provide("listingVersion", readonly(listingVersionState));

    const listingCompetitors = ref([]);
    const competitorsCount = ref(0);

    const competitorLoaded = (competitors) => {
      if (!competitors || competitors.length === 0) {
        return;
      }

      listingCompetitors.value = competitors;
      competitorsCount.value = competitors.length;
    };

    const keywordTrendsComponent = ref();

    const calculateRankScores = async () => {
      blocked.value = true;

      await nextTick();

      let resp = keywordTrendsComponent.value.calculateRankScores();

      listingVersionState.total_score = resp.totalScore;
      listingVersionState.title_score = resp.titleScore;
      listingVersionState.bullet_score = resp.bulletScore;
      listingVersionState.total_rank = calculateCompetitorRank(
        "total",
        listingCompetitors.value,
        resp.totalScore
      );
      listingVersionState.title_rank = calculateCompetitorRank(
        "title",
        listingCompetitors.value,
        resp.titleScore
      );
      listingVersionState.bullet_rank = calculateCompetitorRank(
        "bullet",
        listingCompetitors.value,
        resp.bulletScore
      );

      await nextTick();

      // Detect Sensitive Words
      detectSensitiveWordsFromInputs();

      blocked.value = false;
    };

    const calculateCompetitorRank = (rankType, competitors, score) => {
      let rankSet = new Set();
      let rankArr;

      rankSet.add(score);
      for (let competitor of competitors) {
        rankSet.add(competitor[`${rankType}_score`]);
      }

      rankArr = [...rankSet];
      rankArr = rankArr.sort((a, b) => b - a);

      return rankArr.indexOf(score) + 1;
    };

    const detectSensitiveWordsFromInputs = () => {
      const attrs = [
        "title",
        "bullet_point_1",
        "bullet_point_2",
        "bullet_point_3",
        "bullet_point_4",
        "bullet_point_5",
        "search_term",
        "description",
      ];

      let results = [];
      attrs.forEach((attr) => {
        let detectedWords = detectSensitiveWords(listingVersionState[attr]);
        if (detectedWords.length > 0) {
          results.push({
            title: attr.replaceAll("_", " ").toUpperCase(),
            detectedWords: detectedWords,
          });
        }
      });

      console.table(results);
      keywordTrendsComponent.value.updateDetectedResults(results);
    };

    return {
      blocked,
      loadingProducts,
      loadingListingVersions,
      handleChangeSelectedProductId,

      products,
      listingVersions,
      keywordTrendsComponent,

      listingVersionState,
      confirmDeleteListingVersion,
      saveListingVersion,
      createListingVersion,
      loadListingVersion,
      calculateRankScores,

      competitorsCount,
      competitorLoaded,

      formatNumber,
    };
  },
});
</script>

<style scoped>
.condition-title {
  font-weight: 500;
}

::v-deep .card-score .p-card-content {
  padding: 0;
}
</style>
