import { axiosApiInstance } from "@/services/base";

class ListingService {
  listKeywords(params) {
    return axiosApiInstance.get(`/listing/keywords`, {
      params: params,
    });
  }

  listCompetitors(params) {
    return axiosApiInstance.get(`/listing/competitors`, {
      params: params,
    });
  }

  listTrademarkInspects(payload) {
    return axiosApiInstance.post(`/listing/trademark/inspects`, payload);
  }
}

export default new ListingService();
