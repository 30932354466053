<template>
  <div>
    <div class="flex justify-content-between mb-1">
      <div class="text-xl font-bold">{{ label }}</div>
      <div class="flex align-items-center">
        <div class="text-blue-500 mr-2">
          {{ textBytes }}/{{ maxBytes }} bytes
        </div>
        <div>
          <Button
            icon="pi pi-copy"
            class="p-button-rounded p-button-text"
            @click="copyText"
          />
        </div>
      </div>
    </div>
    <Textarea
      v-model="text"
      :autoResize="true"
      rows="5"
      style="width: 100%"
      id="listing-title"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useVModel, computedAsync } from "@vueuse/core";
import { useToast } from "primevue/usetoast";
import ClipboardJS from "clipboard";

import Button from "primevue/button";
import Textarea from "primevue/textarea";

export default defineComponent({
  components: {
    Button,
    Textarea,
  },
  props: ["label", "modelValue", "maxBytes"],
  setup(props, { emit }) {
    const toast = useToast();

    const text = useVModel(props, "modelValue", emit);
    const textBytes = computedAsync(
      async () => {
        // https://dev.to/rajnishkatharotiya/get-byte-size-of-the-string-in-javascript-20jm
        return new Blob([text.value]).size;
      },
      0 // initial state
    );

    const copyText = () => {
      ClipboardJS.copy(text.value);
      toast.add({
        severity: "success",
        summary: "Copied",
        life: 3000,
      });
    };

    return {
      text,
      textBytes,

      copyText,
    };
  },
});
</script>

<style scoped></style>
